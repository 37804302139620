<script>
import OnDemandForm from "@/views/menu/onDemand/onDemandForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    onDemandService: "onDemandService"
  }
})
export default class OnDemandDetail extends mixins(OnDemandForm) {
  formId = "detail-onDemand-form";

 get decoratedElements() {
    return this.insertAfter(this.allReadonlyFields, {
      id: "entityLink",
      label: this.translations.labels.common_form_entity_link,
      type: "text",
      readonly: true,
      copyToClipboard: true,
    });
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.onDemands_detail;
  }

  async afterCreate () {
    this.model = await this.onDemandService.read(this.$route.params.id);
    this.model.entityLink = "Not yet generated";
    this.isReady = true;

    this.model.entityLink = Utility.generateEntityLink(this?.event, "OnDemand", this.model.id);
  }
}
</script>
